import { P, Text } from '@amzn/stencil-react-components/text';
import React from 'react';

const cssProperties = {
    listStyleType: 'none'
} as React.CSSProperties;

export const modalInstructionsSM = () => {
    return (
      <>
          <br />
          <ul style={cssProperties}>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Please make edits below, add Comment before clicking Submit button.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      The text entered in the Text Area should be wrapped within 'speak' tags.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Click Discard button or hit 'Esc' to close the modal.
                  </P>
              </li>
          </ul>
      </>
    );
};

export const modalInstructionsVS = () => {
    return (
      <>
          <br />
          <ul style={cssProperties}>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Please make edits below, add Comment before clicking Submit button.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Enter non-negative numbers only, and sum of the two numbers should be equal to a hundred.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Click Discard button or hit 'Esc' to close the modal.
                  </P>
              </li>
          </ul>
      </>
    );
};

export const modalInstructionsSS = () => {
    return (
      <>
          <br />
          <ul style={cssProperties}>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Please make edits in SMS Body and add Comment before clicking Submit button.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Click Discard button or hit 'Esc' to close the modal.
                  </P>
              </li>
          </ul>
      </>
    );
};

export const modalInstructionsMS = () => {
    return (
      <>
          <ul style={cssProperties}>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Please add appropriate content below, add Comment before clicking Submit button.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      All fields are compulsory.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Click Discard button or hit 'Esc' to close the modal.
                  </P>
              </li>
          </ul>
      </>
    );
};

export const modalInstructionsSTSS = () => {
    return (
      <>
          <ul style={cssProperties}>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      All fields are compulsory.
                  </P>
              </li>
              <li>
                  <P fontSize='T100' textAlign='justify'>
                      Click Close button or hit 'Esc' to close the modal.
                  </P>
              </li>
          </ul>
      </>
    );
};

export const tooltipInstructionsSTSS = (businessGrouptoBeChecked: string) => {
    if (businessGrouptoBeChecked === 'ERC' || businessGrouptoBeChecked === 'CRC') {
        return (
            <Text fontSize='T100' textAlign='justify'>
                Case number for {businessGrouptoBeChecked} has 8 or 9 digits only.
            </Text>
        );
    } else if (businessGrouptoBeChecked === 'GWA-UK') {
        return (
            <Text fontSize='T100' textAlign='left'>
                Case number for GWA-UK start with 'D', 'E', or 'V' followed by 8 - 10 digits.
            </Text>
        );
    } else {
        return (
            <Text fontSize='T100' textAlign='left'>
                Case number for {businessGrouptoBeChecked} consists of digits only.
            </Text>
        );
    }
};

